import React from 'react';
import './PrivacyPolicy.css';
import { Link } from 'react-router-dom'; // Импортируем Link для навигации

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Политика конфиденциальности</h1>
      <p>Настоящая Политика конфиденциальности (далее — «Политика») регулирует обработку персональных данных пользователей сайта <a href="https://muraestate.ru">muraestate.ru</a> (далее — «Сайт»), принадлежащего компании Mura Estate (далее — «Компания»).</p>
      <p>Используя Сайт, вы соглашаетесь с условиями данной Политики. Если вы не согласны с условиями, пожалуйста, воздержитесь от использования Сайта.</p>
      <h2>1. Какие данные мы собираем</h2>
      <p>Мы можем собирать следующие персональные данные пользователей:</p>
      <ul>
        <li>Имя и фамилия;</li>
        <li>Контактные данные (телефон, email);</li>
        <li>Данные, указанные в заявках на обратную связь или запросах информации;</li>
        <li>Информация о поведении на Сайте (файлы cookie, IP-адрес, данные браузера).</li>
      </ul>
      <h2>2. Цели сбора данных</h2>
      <p>Мы используем ваши данные для следующих целей:</p>
      <ul>
        <li>Обработка заявок на покупку или аренду недвижимости;</li>
        <li>Предоставление консультаций и обратной связи;</li>
        <li>Улучшение работы Сайта и пользовательского опыта;</li>
        <li>Проведение маркетинговых кампаний и информирование о новых объектах недвижимости.</li>
      </ul>
      <h2>3. Обработка и хранение данных</h2>
      <p>Все данные обрабатываются с соблюдением норм законодательства РФ о персональных данных. Мы предпринимаем необходимые организационные и технические меры для защиты ваших данных от несанкционированного доступа.</p>
      <p>Персональные данные хранятся на защищенных серверах. Срок хранения данных — до достижения целей их обработки, но не более 3 лет с момента их предоставления, если иное не предусмотрено законодательством.</p>
      <h2>4. Передача данных третьим лицам</h2>
      <p>Мы не передаем ваши персональные данные третьим лицам без вашего согласия, за исключением случаев, предусмотренных законодательством.</p>
      <h2>5. Использование cookie</h2>
      <p>Сайт использует файлы cookie для обеспечения корректной работы и анализа пользовательского поведения. Вы можете отключить использование cookie в настройках вашего браузера, однако это может повлиять на функциональность Сайта.</p>
      <h2>6. Права пользователей</h2>
      <p>Вы имеете право:</p>
      <ul>
        <li>Узнать, обрабатываются ли ваши данные, и запросить копию своих данных;</li>
        <li>Потребовать изменения или удаления ваших данных;</li>
        <li>Отозвать согласие на обработку данных.</li>
      </ul>
      <p>Для реализации своих прав вы можете обратиться по электронной почте: <a href="mailto:ask@muraestate.ru">ask@muraestate.ru</a>.</p>
      <h2>7. Возрастные ограничения</h2>
      <p>Сайт не предназначен для использования лицами младше 18 лет без согласия родителей или законных представителей.</p>
      <h2>8. Изменения в Политике конфиденциальности</h2>
      <p>Мы оставляем за собой право вносить изменения в настоящую Политику. Актуальная версия Политики всегда доступна по адресу: <a href="https://muraestate.ru">https://muraestate.ru</a>.</p>
      <h2>9. Контакты</h2>
      <p>Если у вас возникли вопросы по поводу настоящей Политики или обработки ваших данных, вы можете связаться с нами:</p>
      <p><strong>Mura Estate</strong><br/>
      Email: <a href="mailto:ask@muraestate.ru">ask@muraestate.ru</a><br/>
      Сайт: <a href="https://muraestate.ru">https://muraestate.ru</a></p>
      <p>Дата последнего обновления: 23.01.2025</p>
    </div>
  );
};

export default PrivacyPolicy;

