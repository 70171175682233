import React, { useState } from "react";
import "./FloatingSocials.css";
import { FaWhatsapp, FaTelegramPlane, FaVk, FaPhoneAlt } from "react-icons/fa";

const FloatingSocials = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleMenu = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="floating-socials">
      {/* Кнопка для открытия/закрытия меню */}
      <button className={`floating-button ${isExpanded ? "expanded" : ""}`} onClick={toggleMenu}>
        {isExpanded ? (
          "✖"
        ) : (
          <>
            Пишите, мы онлайн
            <span className="blinking-dot"></span>
          </>
        )}
      </button>
      {/* Список социальных иконок */}
      {isExpanded && (
        <div className="socials-menu">
          <a href="https://wa.me/1234567890" target="_blank" rel="noopener noreferrer" className="social-item">
            <FaWhatsapp className="social-icon whatsapp" />
            <span>WhatsApp</span>
          </a>
          <a href="https://t.me/MURAESTATE_bot" target="_blank" rel="noopener noreferrer" className="social-item">
            <FaTelegramPlane className="social-icon telegram" />
            <span>Telegram</span>
          </a>
          {/* <a href="https://vk.com/yourpage" target="_blank" rel="noopener noreferrer" className="social-item">
            <FaVk className="social-icon vk" />
            <span>ВКонтакте</span>
          </a> */}
          <a href="tel:+74951086977" target="_blank" rel="noopener noreferrer" className="social-item">
            <FaPhoneAlt className="social-icon phone" />
            <span>Позвонить</span>
          </a>
        </div>
      )}
    </div>
  );
};

export default FloatingSocials;
