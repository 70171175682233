import React, { useState, useEffect } from 'react';
import './Navbar.css';
import Logo from '../../logo.png';
import { FaWhatsapp, FaTelegramPlane, FaPhoneAlt } from 'react-icons/fa';

const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className={`nav-wrapper ${isScrolled ? 'scrolled' : ''}`}>
            <div className='nav-content'>
                {/* Используем <a> для полной перезагрузки страницы при клике на логотип */}
                <a href="/">
                    <img className={`logo ${isScrolled ? 'scrolled-logo' : ''}`} src={Logo} alt="Logo" />
                </a>
                <div className={`contact-info ${isScrolled ? 'scrolled-info' : ''}`}>
                    {/* WhatsApp Icon */}
                    <a href="https://wa.me/74952751211" target="_blank" rel="noopener noreferrer" className="icon">
                        <FaWhatsapp />
                    </a>
                    
                    {/* Telegram Icon */}
                    <a href="https://t.me/MURAESTATE_bot" target="_blank" rel="noopener noreferrer" className="icon">
                        <FaTelegramPlane />
                    </a>
                    
                    {/* Phone Number */}
                    <a href="tel:+74951086977" className="phone-number">
                        +7 (495) 108-69-77
                    </a>
                    
                    {/* Phone Icon */}
                    <a href="tel:+74951086977" className="phone-icon">
                        <FaPhoneAlt />
                    </a> {/* Иконка телефона, кликабельная для звонка */}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
