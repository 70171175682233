import React from 'react';
import './Item.css';

const ItemCard = ({ item, onClick }) => {
  return (
    <div 
      className="grid-item" 
      style={{ backgroundImage: `url(${item.imageUrl})` }}
      onClick={onClick}
    >
      {/* Содержимое, такое как текст или иконка */}
      <div className="grid-item-content">
        <img src={item.icon} alt={item.title} />
      </div>
      <div className="hover-overlay">
        <div className="overlay-content">
          <h3>{item.title}</h3>
          {/* <p>Квартиры <span className="highlight">от {item.price_from}</span></p> */}
          <p>Срок сдачи <span className="highlight">{item.deadline} г.</span></p>
          <span className="clickable-icon">➔</span>
        </div>
      </div>
    </div>
  );
}

export default ItemCard;