import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa"; // Импортируем иконку стрелки
import "./FeaturedProperties.css";

import River from '../../assets/quiz/near-river.jpg';
import TTK from '../../assets/quiz/inside-ttk.jpg';
import Komfort from '../../assets/quiz/comfort-within-mkad.jpg';

const FeaturedProperties = () => {
  return (
    <div className="featured-properties">
      <div className="property-container">
        
        {/* Первый блок */}
        <div 
          className="property-block" 
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${River}) center center / cover no-repeat`,
          }}
        >
          <div className="property-content">
            <h3 className="property-title">Новостройки у Москвы реки</h3>
            <Link to="/near-river">
              <button className="property-button">
                <FaArrowRight className="arrow-icon" /> {/* Стрелка */}
              </button>
            </Link>
          </div>
        </div>

        {/* Второй блок */}
        <div 
          className="property-block" 
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${TTK}) center center / cover no-repeat`,
          }}
        >
          <div className="property-content">
            <h3 className="property-title">Новостройки в пределах ТТК</h3>
            <Link to="/inside-ttk">
              <button className="property-button">
                <FaArrowRight className="arrow-icon" /> {/* Стрелка */}
              </button>
            </Link>
          </div>
        </div>

        {/* Третий блок */}
        <div 
          className="property-block" 
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${Komfort}) center center / cover no-repeat`,
          }}
        >
          <div className="property-content">
            <h3 className="property-title">Новостройки комфорт-класса</h3>
            <Link to="/comfort-within-mkad">
              <button className="property-button">
                <FaArrowRight className="arrow-icon" /> {/* Стрелка */}
              </button>
            </Link>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default FeaturedProperties;
