import React, { useEffect, useState, useRef } from 'react';
import './Catalog.css';
import CardsSetAdjusting from './CatalogSettings/CardsSetAdjusting';
import CardSet from './CatalogCard/CardsSet';
import YandexMap from './YandexMap/YandexMap';
import SortSettings from './SortSettings/SortSettings'; // Добавляем компонент для сортировки
import { AreaEnum } from '../../utils/data/AreaEnum';
import { useArea } from '../AreaContext'; // Используем контекст
import { FaMapMarkerAlt } from 'react-icons/fa'; // Импортируем иконку

const Catalog = () => {
  const { selectedAreas, setSelectedAreas, highlightedArea, setHighlightedArea } = useArea();
  const [showMap, setShowMap] = useState(false);
  const [closeDropdown, setCloseDropdown] = useState(false);
  const [filteredCards, setFilteredCards] = useState([]);
  const [realEstateCards, setRealEstateCards] = useState([]);
  const [sortedCards, setSortedCards] = useState([]); // Храним отсортированные карточки
  const settingsRef = useRef(null); // Ссылка на настройки выпадающих списков

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://muraestate.ru/api/realestate'); // Замените на ваш эндпоинт
        const data = await response.json();
        setRealEstateCards(data);
        setFilteredCards(data); // Изначально отобразить все карточки
        setSortedCards(data); // Изначально сохраняем все карточки
      } catch (error) {
        console.error('Ошибка загрузки данных:', error);
      }
    };

    fetchData();
  }, []);

  const uniqueAreas = Object.values(AreaEnum);

  const applyFilter = (selectedAreas) => {
    let filtered = realEstateCards;
    if (selectedAreas.length > 0) {
      filtered = realEstateCards.filter(card => selectedAreas.includes(card.area));
    }
    setFilteredCards(filtered);
    setSortedCards(filtered); // Обновляем отсортированные карточки на основе фильтра
  };

  const applySort = (sortType) => {
    let sorted = [...filteredCards]; // Сортируем только отфильтрованные карточки
    if (sortType === 'cheaper') {
      sorted.sort((a, b) => a.priceFrom - b.priceFrom); // Сортировка по возрастанию цены
    } else if (sortType === 'more-expensive') {
      sorted.sort((a, b) => b.priceFrom - a.priceFrom); // Сортировка по убыванию цены
    }
    setSortedCards(sorted);
  };

  useEffect(() => {
    applyFilter(selectedAreas); // Применяем фильтр при изменении выбранных областей
  }, [selectedAreas]);

  const toggleMap = () => {
    setShowMap(!showMap);
    setCloseDropdown(true);

    if (!showMap) {
      setSelectedAreas([]);
      setFilteredCards(realEstateCards);
    } else {
      setTimeout(() => setCloseDropdown(false), 0);
    }
  };

  // Закрываем выпадающие списки при клике вне
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (settingsRef.current && !settingsRef.current.contains(event.target)) {
        setCloseDropdown(true); // Закрываем выпадающие списки
      }
    };

    document.addEventListener('mousedown', handleClickOutside); // Обрабатываем клик
    return () => {
      document.removeEventListener('mousedown', handleClickOutside); // Очищаем обработчик
    };
  }, [settingsRef]);

  return (
    <section className='catalog-container'>
      <div className='catalog-settings' ref={settingsRef}>
        <div className='catalog-card card-1'>
          <CardsSetAdjusting
            name={<>Расположение <FaMapMarkerAlt className="icon-spacing" /></>} // Передаем иконку вместе с текстом
            areas={uniqueAreas}
            onApplyFilter={applyFilter} // Передаем функцию фильтрации
            closeDropdown={closeDropdown}
          />
        </div>
        <div className='catalog-card card-3'>
          <SortSettings onApplySort={applySort} /> {/* Компонент сортировки */}
        </div>
      </div>
      <div className={`catalog-card-container ${showMap ? 'hidden' : ''}`}>
        {!showMap && <CardSet filteredCards={sortedCards} />} {/* Отображаем отсортированные карточки */}
      </div>
      {showMap && (
        <div className="map-container">
          <YandexMap highlightedArea={highlightedArea} />
        </div>
      )}
    </section>
  );
};

export default Catalog;
