import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { Link, useNavigate } from 'react-router-dom';
import './ModalCallback.css';

const ModalCallback = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    topic: 'Новостройки',
  });
  const [error, setError] = useState('');
  const [currentStep, setCurrentStep] = useState(1); // Шаги: 1 — форма, 2 — спасибо
  const navigate = useNavigate();

  // Получение clientId из Google Analytics
  const getClientId = () => {
    return new Promise((resolve, reject) => {
      if (window.gtag) {
        window.gtag('get', 'G-XXXXXXXXXX', 'client_id', (clientId) => {
          if (clientId) resolve(clientId);
          else reject('Client ID not available');
        });
      } else {
        reject('gtag.js not found');
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === 'phoneNumber' && error) {
      setError(''); // Убираем ошибку при изменении телефона
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.phoneNumber || formData.phoneNumber.includes('_')) {
      setError('Пожалуйста, введите корректный номер телефона.');
      return;
    }

    try {
      // Получаем clientId
      const clientId = await getClientId();

      const data = {
        phoneNumber: formData.phoneNumber,
        name: formData.name,
        queryType: formData.topic,
        clientId: clientId,
      };

      const response = await fetch('http://localhost:8080/api/amocrm/callback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        console.log('Данные успешно отправлены:', data);

        // Логируем успешное событие
        if (window.ym) {
          window.ym(348687385, 'reachGoal', 'Callback sent');
        }

        // Переход на шаг "Спасибо"
        setCurrentStep(2);

        // Автоматическое закрытие через 2 секунды
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        console.error('Ошибка при отправке данных:', response.statusText);
      }
    } catch (error) {
      console.error('Ошибка:', error);
    }
  };

  const handlePrivacyLinkClick = (e) => {
    e.preventDefault(); // Предотвращаем переход по ссылке
    onClose(); // Закрываем модальное окно
    navigate('/privacy-policy'); // Перенаправляем на страницу политики конфиденциальности
  };

  const renderForm = () => (
    <>
      <h2 className="modal-call-back-title">Свяжитесь с нами</h2>
      <p className="modal-call-back-description">
        Мы готовы ответить на ваши вопросы. Заполните форму и получите консультацию.
      </p>
      <form onSubmit={handleSubmit} className="modal-call-back-form">
        <InputMask
          mask="+7 (999) 999-99-99"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          className="modal-call-back-input"
          placeholder="Ваш телефон"
        />
        <input
          type="text"
          name="name"
          placeholder="Ваше имя"
          className="modal-call-back-input"
          value={formData.name}
          onChange={handleChange}
        />
        <select
          name="topic"
          value={formData.topic}
          onChange={handleChange}
          className="modal-call-back-input"
        >
          <option value="Новостройки">Новостройки</option>
          <option value="Ипотека">Ипотека</option>
        </select>
        {error && <div className="modal-call-back-error">{error}</div>}
        <button type="submit" className="modal-call-back-submit-btn">
          Отправить заявку
        </button>
        <p className="modal-call-back-note">
          Нажимая «Отправить заявку», вы соглашаетесь с{' '}
          <Link
            to="/privacy-policy"
            className="modal-call-back-privacy-link"
            onClick={handlePrivacyLinkClick}
          >
            политикой конфиденциальности
          </Link>.
        </p>
      </form>
    </>
  );

  const renderThankYou = () => (
    <div className="thank-you-page">
      <h2 className="modal-call-back-title">Спасибо!</h2>
      <p className="modal-call-back-description">
        Мы получили вашу заявку и скоро свяжемся с вами.
      </p>
    </div>
  );

  return (
    <div className="modal-call-back-overlay" onClick={onClose}>
      <div className="modal-call-back-container" onClick={(e) => e.stopPropagation()}>
        <button className="modal-call-back-close-button" onClick={onClose}>
          ×
        </button>
        <div className="modal-call-back-content">
          {currentStep === 1 ? renderForm() : renderThankYou()}
        </div>
      </div>
    </div>
  );
};

export default ModalCallback;
