import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Quiz.css";
import NearRiver from "../../assets/quiz/near-river.jpg";
import InsideTTK from "../../assets/quiz/inside-ttk.jpg";
import ComfortWithinMKAD from "../../assets/quiz/comfort-within-mkad.jpg";
import ApartmentQuiz from "../../components/apartmentPage/apartmentQuiz/ApartmentQuiz";

const quizData = {
  nearRiver: {
    title: "Новостройки у Москвы реки",
    description: "Ответьте на 2 вопроса, и мы подберем квартиру с видом на реку, соответствующую вашим требованиям.",
    image: NearRiver,
  },
  insideTTK: {
    title: "Новостройки в пределах ТТК",
    description: "Ответьте на 2 вопроса и мы подберем для вас лучшие предложения, которые идеально подойдут для вашего стиля жизни.",
    image: InsideTTK,
  },
  comfortWithinMKAD: {
    title: "Новостройки комфорт-класса в пределах МКАД",
    description: "Ответьте на 2 вопроса и мы подберем для вас лучшие предложения комфорт-класса в пределах МКАД.",
    image: ComfortWithinMKAD,
  },
};

const Quiz = ({ type }) => {
  const [isQuizStarted, setIsQuizStarted] = useState(false);
  const navigate = useNavigate();

  const handleStart = () => {
    setIsQuizStarted(true);
  };

  const handleQuizComplete = () => {
    navigate("/");
  };

  const handleGoHome = () => {
    navigate("/");
  };

  const quizContent = quizData[type] || quizData.nearRiver;

  return (
    <div className={`quiz-container ${isQuizStarted ? "quiz-active" : ""}`}>
      <div
        className="quiz-background"
        style={{
          backgroundImage: `url(${quizContent.image})`,
        }}
      ></div>
      {!isQuizStarted ? (
      <div className="gradient-overlay">
      <div className="quiz-content-wrapper">
        <div className="quiz-start-content">
          <h1>{quizContent.title}</h1>
          <p>{quizContent.description}</p>
          <button onClick={handleStart} className="start-button">
            Оставить заявку
          </button>
        </div>
      </div>
      <button onClick={handleGoHome} className="home-button">
        На главную
      </button>
    </div>
        
      ) : (
        <ApartmentQuiz onComplete={handleQuizComplete} />
      )}
    </div>
  );
};

export default Quiz;
