import React, { useState, useRef, useEffect } from 'react';
import './CardsSetAdjisting.css';
import { useArea } from '../../AreaContext';

const CardsSetAdjusting = ({
  name,
  areas = [],
  closeDropdown,
  isMapToggle,
  showMap,
  onApplyFilter,
  onToggleDropdown
}) => {
  const { selectedAreas, setSelectedAreas, setHighlightedArea } = useArea();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [temporarySelectedAreas, setTemporarySelectedAreas] = useState([...selectedAreas]);
  const dropdownRef = useRef(null);

  const handleAreaChange = (area) => {
    const newSelectedAreas = temporarySelectedAreas.includes(area)
      ? temporarySelectedAreas.filter(selected => selected !== area)
      : [...temporarySelectedAreas, area];
    setTemporarySelectedAreas(newSelectedAreas);
  };

  const handleClearSelection = () => {
    setTemporarySelectedAreas([]);
    setSelectedAreas([]);
    setHighlightedArea(null);
    onApplyFilter([]);
    setIsDropdownOpen(false);
  };

  const applyFilter = () => {
    setSelectedAreas(temporarySelectedAreas);
    setHighlightedArea(null);
    onApplyFilter(temporarySelectedAreas);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    setTemporarySelectedAreas(selectedAreas);
  }, [selectedAreas]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Закрываем выпадающий список при клике вне его
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className="catalog-settings-card"
      onClick={() => !isMapToggle && setIsDropdownOpen(!isDropdownOpen)}
      ref={dropdownRef}
    >
      <div className="filter-label">
        {isMapToggle ? (
          <span>{showMap ? 'Закрыть карту' : 'Показать на карте'}</span>
        ) : (
          <>
            {name}
            {temporarySelectedAreas.length > 0 && (
              <span className="filter-count">{temporarySelectedAreas.length}</span>
            )}
          </>
        )}
      </div>
      {isDropdownOpen && areas.length > 0 && (
        <div className="dropdown" onClick={(e) => e.stopPropagation()}>
          {areas.map((area) => (
            <div
              key={area}
              className="dropdown-item"
              onClick={() => handleAreaChange(area)}
            >
              <input
                type="checkbox"
                id={`area-${area}`}
                checked={temporarySelectedAreas.includes(area)}
                onChange={() => handleAreaChange(area)}
              />
              <label htmlFor={`area-${area}`}>{area}</label>
            </div>
          ))}
          <button className="apply-button" onClick={applyFilter}>Показать</button>
          {temporarySelectedAreas.length > 0 && (
            <button className="clear-button" onClick={handleClearSelection}>
              Очистить
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default CardsSetAdjusting;
